import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("app"));

const AppComponent = React.lazy(() => import("./App"));

root.render(
  <BrowserRouter>
    <AppComponent />
  </BrowserRouter>
);
